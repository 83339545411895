import React from "react";
import { TypeAnimation } from "react-type-animation";
import "../styles/Banner.css";
import banner from "../assets/banner.jpg"; // Adjust the path if necessary

const Banner = () => {
  return (
    <div className="banner">
      <div className="banner-content">
        <div className="text-content">
          <h1 style={{ fontSize: "48px", color: "black", textAlign: "left" }}>
            Craft Your{" "}
            <TypeAnimation
              sequence={[
                "Dream",
                2000,
                "Garden",
                2000,
                "Escape",
                2000,
                "Sanctuary",
                2000,
              ]}
              wrapper="span"
              cursor={true}
              repeat={Infinity}
              style={{ color: "black" }}
            />
          </h1>
          <p>
            Welcome to Lily Garden, your one-stop shop for creating the perfect
            outdoor haven. We're a leading provider of professional gardening
            and landscaping services across Hyderabad, Warangal, Nalgonda,
            Hanamkonda, and Karimnagar.
          </p>
          <p>
            With over 20 years of experience, our expert team can bring your
            vision to life, whether you dream of a tranquil garden retreat, a
            functional outdoor living area, or a lush, green lawn.
          </p>
        </div>
        <div className="image-content">
          <img src={banner} alt="Service" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
