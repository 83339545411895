import React from "react";
import "../styles/Footer.css";

const Footer = () => {
  const copyright = String.fromCodePoint(0x00a9);
  return (
    <footer className="footer">
      {copyright} 2024 Lily Garden. All rights reserved.
    </footer>
  );
};

export default Footer;
