import React from "react";
import "../styles/Gallery.css";
import image1 from "../assets/pic1.jpg";
import image2 from "../assets/pic2.png";
import image3 from "../assets/pic3.jpg";
import image4 from "../assets/pic4.jpg";

const Gallery = () => {
  return (
    <div>
      <h2
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "35px",
          fontWeight: "bold",
        }}
      >
        Our Work
      </h2>
      <div className="image-gallery">
        <img src={image1} alt="Work 1" />
        <img src={image2} alt="Work 2" />
        <img src={image3} alt="Work 3" />
        <img src={image4} alt="Work 4" />
      </div>
    </div>
  );
};

export default Gallery;
