import React from "react";
import "../styles/Header.css";
import logo from "../assets/logo.png";
import { FaPhoneAlt } from "react-icons/fa"; // Importing a phone icon from react-icons

const Header = () => {
  return (
    <header className="header">
      <img
        src={logo}
        style={{ width: "80px", height: "50px" }}
        alt="Lily Garden Logo"
      />

      <div className="logo">Lily Garden</div>
      <div className="phone">
        <a href="tel:+919515715388" className="phone-link">
          <FaPhoneAlt className="phone-icon" />
          +91 95157 15388
        </a>
      </div>
    </header>
  );
};

export default Header;
