import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import "../styles/WhatsAppIcon.css";

const WhatsAppIcon = () => {
  return (
    <a
      href="https://wa.me/9515715388"
      className="whatsapp-icon"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaWhatsapp size={80} color="#25D366" />
    </a>
  );
};

export default WhatsAppIcon;
